import React, { useEffect,useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './AllInUserInfoComponent.css'; // Import the CSS file
import Logo from './img/Andre elementer/Logo.png';
import section1Do from './img/Punkt 1/01 Do.png';
import section1 from './img/Punkt 1/01.png';
import section1Dont from './img/Punkt 1/01 dont.png';
import section1Number from './img/Punkt 1/1.png';
import section2Do from './img/Punkt 2/02 do.png';
import section2 from './img/Punkt 2/02.png';
import section2Dont from './img/Punkt 2/02 dont.png';
import section2Number from './img/Punkt 2/2.png';
import section3Do from './img/Punkt 3/03 do.png';
import section3 from './img/Punkt 3/03.png';
import section3Dont from './img/Punkt 3/03 dont.png';
import section3Number from './img/Punkt 3/3.png';
import baseApi from '../../_api/base.api';
import Loader from '../common/loader/loader';
import { useAlert, positions, transitions,types } from "react-alert";
import Success from '../invitation/Success';

export default function AllInUserInfoComponent() {
    const alert = useAlert();
    const [name, setName] = useState('');
    const [employeeNumber, setEmployeeNumber] = useState<number>();
    const [employeeNumberError, setEmployeeNumberError] = useState('');
    const [imageBlob, setImageBlob] = useState(null);
    const [imageError, setImageError] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [fileName, setFileName] = useState('');
    const [loadingProgress, setLoadingProgress] = useState(false);

    useEffect(() => {
        // Find the element by its ID and hide it
        const headerNavBarButton = document.getElementById('headerNavBarButton');
        if (headerNavBarButton) {
            headerNavBarButton.style.display = 'none';
        }
    }, []); // Empty dependency array ensures this runs only once on component mount
    
    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            const targetWidth = 2280;

            // Check if the image's width is too small
            if (img.width < targetWidth) {
                setImageBlob(null);
                setImageError('Image width must be at least ' + targetWidth + ' pixels.');
                setFileName('');
                return;
            }

            let width = targetWidth;
            let height = (img.height / img.width) * targetWidth; // Scale height proportionally to width

            // Create a canvas to resize the image
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob: any) => {
                    if (blob) {
                        setImageBlob(blob);
                        setImageError('');
                        setFileName(file.name);
                    } else {
                        setImageBlob(null);
                        setImageError('Error processing image. Please try again.');
                        setFileName('');
                    }
                }, file.type);
            }
        };
    };


    const handleEmployeeNumberChange = (e: any) => {
        setEmployeeNumberError("")
        if (employeeNumber && (employeeNumber < 100000 || employeeNumber > 999999)) {
            setEmployeeNumberError('Must be between 100000 and 999999.');
        }
    };

    const handleCancel = () => {
        setName('');
        setEmployeeNumber(0);
        setEmployeeNumberError('');
        setImageBlob(null);
        setImageError('');
        setIsCheckboxChecked(false);
        setFileName('');
    };

    const handleSubmit = () => {
        if (name && employeeNumber && imageBlob && isCheckboxChecked) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('employeeNumber', employeeNumber?.toString());
            if (imageBlob) {
                formData.append('image', imageBlob ?? "");
            } else {
                console.error('Image blob is null. Please upload a valid image.');
            }
            setLoadingProgress(true);
            baseApi.Invitations.PostAllInUserInfo(formData).then(
                (response: any) => {
                    setLoadingProgress(false);
                    if (response) {
                        const options = {
                            position: positions.BOTTOM_CENTER,
                            timeout: 0,
                            transition: transitions.SCALE,
                            type: types.SUCCESS,
                        }
                        alert.show(response?.message, options);
                        handleCancel();
                    }
                }).catch((reason: any) => {
                    const options = {
                        position: positions.BOTTOM_CENTER,
                            timeout: 0,
                            transition: transitions.SCALE,
                            type: types.ERROR,
                        }
                        alert.show("An error has occured", options);
                    setLoadingProgress(false);
                });
        }
    };

    const isSubmitDisabled = !(name && employeeNumber && !employeeNumberError && imageBlob && isCheckboxChecked);
    if (loadingProgress) return <Loader loading={loadingProgress}></Loader>
    return (
        <>
            <CssBaseline />
            <div className="parent-container">
                <div className="top-image-div">
                    <img src={Logo} alt="0" className="top-image" />
                </div>
                <div className="top-heading-div">
                    {/*<h2>VI HAR BRUG FOR DIN HJ&AElig;LP!</h2>*/}
                    <h2>Upload et billede af dig og dine buddies i fuld allin-&aring;nd!</h2>
                </div>
                <div className="top-text-div">
                    {/*I forbindelse med Allin25 laver vi en mosaik med billeder af jer medarbejdere til en video, som viser alle gode kolleger. Derfor har vi brug for mindst 1000 billeder af jer.*/}
                    Der skal skr&aring;les til koncerter, afhentes drikke i barer, spilles spil p&aring; gr&aelig;sset og gives high fives hele dagen til allin25! Men med hvem? Hvem er dine buddies til allin25?
                </div>
                {/*<div className="grid-container">*/}
                {/*    <div className="cardova">*/}
                {/*        <div className="Flexi">*/}
                {/*            <div>*/}
                {/*                <img src={section1Number} alt="1" className="numberImage" />*/}
                {/*            </div>*/}
                {/*            &nbsp;*/}
                {/*            <div>*/}
                {/*                Billedet skal v&aelig;re i bredformat.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="grid-item1">*/}
                {/*            <img src={section1} alt="11" className="sectionImage1" />*/}
                {/*            */}{/*<img src={section1Dont} alt="12" className="sectionImage" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="cardova">*/}
                {/*        <div className="Flexi">*/}
                {/*            <div>*/}
                {/*                <img src={section2Number} alt="2" className="numberImage" />*/}
                {/*            </div>*/}
                {/*            &nbsp;*/}
                {/*            <div>*/}
                {/*                Billedet skal tydeligt vise dit ansigt. Der m&aring; ikke v&aelig;re andre p&aring; billedet.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="grid-item2">*/}
                {/*            <img src={section2} alt="21" className="sectionImage2" />*/}
                {/*            */}{/*<img src={section2Dont} alt="22" className="sectionImage" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="cardova">*/}
                {/*        <div className="Flexi">*/}
                {/*            <div>*/}
                {/*                <img src={section3Number} alt="3" className="numberImage" />*/}
                {/*            </div>*/}
                {/*            &nbsp;*/}
                {/*            <div>*/}
                {/*                Billedet skal v&aelig;re i h&oslash;j opl&oslash;sning. (Et billede fra din telefon er godt nok)*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="grid-item3">*/}
                {/*            <img src={section3} alt="31" className="sectionImage3" />*/}
                {/*            */}{/*<img src={section3Dont} alt="32" className="sectionImage" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="grid-container2">
                    <div className="cardova">
                        <div className="Flexi">
                            <div>
                                <img src={section1Number} alt="1" className="numberImage" />
                            </div>
                            &nbsp;
                            <div>
                                Billedet skal v&aelig;re i bredformat.
                            </div>
                        </div>
                        <div className="grid-item1">
                            <img src={section1} alt="11" className="sectionImage1" />
                            {/*<img src={section1Dont} alt="12" className="sectionImage" />*/}
                        </div>
                    </div>
                    <div className="cardova">
                        <div className="Flexi">
                            <div>
                                <img src={section2Number} alt="2" className="numberImage" />
                            </div>
                            &nbsp;
                            <div>
                                Billedet skal v&aelig;re i h&oslash;j opl&oslash;sning. (Et billede fra din telefon er godt nok)
                            </div>
                        </div>
                        <div className="grid-item3">
                            <img src={section3} alt="31" className="sectionImage3" />
                            {/*<img src={section3Dont} alt="32" className="sectionImage" />*/}
                        </div>
                    </div>
                </div>
                <div className="grid-container-Below">
                    <div className="form-item">
                        <label htmlFor="name">Navn:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />
                        <br />
                        <label htmlFor="employeeNumber">L&oslash;nnummer:</label>
                        <input
                            type="number"
                            id="employeeNumber"
                            name="employeeNumber"
                            className="form-input"
                            required
                            min="100000"
                            max="999999"
                            value={employeeNumber}
                            onBlur={handleEmployeeNumberChange}
                            onChange={(e) => setEmployeeNumber(+(e.target.value))}
                        />
                        {employeeNumberError && <p className="error-text">{employeeNumberError}</p>}
                        <br />
                        <br />
                        <label htmlFor="imageUpload" className="upload-button">Upload billede</label>
                        <input
                            type="file"
                            id="imageUpload"
                            name="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                        />
                        {fileName && <p>{fileName}</p>}
                        {imageError && <p className="error-text">{imageError}</p>}
                    </div>
                    <div className="form-item center-align">
                        <div className="flex start">
                            <input
                                type="checkbox"
                                id="confirm"
                                name="confirm"
                                className="checkbox"
                                required
                                checked={isCheckboxChecked}
                                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                            />
                            &nbsp;
                            <label htmlFor="confirm" className="checkbox-label">
                                <p className="lessMarginBottom">Jeg bekr&aelig;fter at have l&aelig;st nedenst&aring;ende, og at mine kolleger p&aring; billeder er med p&aring;, at billedet bruges ifm allin25.</p>
                                <p className="lessMarginBottom">N&aring;r du uploader et billede, skal du sikre, at dine kolleger, som er med p&aring; billedet, ved og samtykker til at billedet kan blive vist p&aring; storsk&aelig;rme til allin25 og p&aring; medarbejderapp'en. Kunder m&aring; ikke fremg&aring; p&aring; billedet.</p>
                                <p className="lessMarginBottom">Du eller dine kolleger altid efterf&oslash;lgende kontakte <a href="mailto:allin25@sallinggroup.com"> allin25@sallinggroup.com</a> og bede om, at billedet ikke bruges.</p>
                                <p className="lessMarginBottom">Du kan l&aelig;se mere om vores behandling af dine personoplysninger i denne <a href="https://dscloud.sharepoint.com/:b:/s/myKnowledge/EWnEWUQrIZhPvuUdhSvJdAAB1zVP-j-f7QmnjE1HUtScVA?e=3bTPKJ" target="_blank" rel="noopener noreferrer">privatlivspolitik</a>.</p>
                                {/*Jeg bekr&aelig;fter at have l&aelig;st f&oslash;lgende:<br />*/}
                                {/*Det er frivilligt at uploade et billede til brug for AllIn-videoen. Billedet m&aring; kun vise dig selv.*/}
                                {/*Hvis dit billede udv&aelig;lges, vil det blive en del af en video. Videoen vil blive uploadet til Intranettet,*/}
                                {/*den kan blive offentliggjort p&aring; LinkedIn, og den kan blive vist p&aring; storsk&aelig;rm p&aring; HQ*/}
                                {/*og til AllIn arrangementet. Du kan altid rette henvendelse til os via*/}
                                {/*<a href="mailto:allin25@sallinggroup.com"> allin25@sallinggroup.com</a>, hvis du ikke &oslash;nsker at deltage*/}
                                {/*alligevel, eller hvis du har sp&oslash;rgsm&aring;l til videoen. Du skal dog v&aelig;re opm&aelig;rksom p&aring;, at*/}
                                {/*n&aring;r videoen f&oslash;rst er lavet, kan dit billede ikke uden videre slettes fra videoen.*/}
                                {/*Du kan l&aelig;se mere om vores behandling af dine personoplysninger i*/}
                                {/*denne <a href="https://dscloud.sharepoint.com/:b:/s/myKnowledge/EWnEWUQrIZhPvuUdhSvJdAAB1zVP-j-f7QmnjE1HUtScVA?e=3bTPKJ" target="_blank" rel="noopener noreferrer">privatlivspolitik</a>.*/}
                            </label>
                        </div>
                        <div className="flex1122">
                            <button className="submit-button" onClick={handleCancel}>Annuller</button>
                            &nbsp;
                            <button
                                className={isSubmitDisabled ? 'cancel-button' : 'submit-button'} // Conditional class
                                disabled={isSubmitDisabled}
                                onClick={handleSubmit}
                            >
                                Indsend
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

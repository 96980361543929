import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { AppContext } from '../Context'
import BarForm from './BarForm';
import BarInvitationConfirm from './BarInvitationConfirm';
import BarInvitationSuccess from './BarInvitationSuccess';
import { DateTime } from "luxon";



// Step titles
const labels = ['Bar Invitation Form', 'Save & Confirmation'];

const handleSteps = (step: number) => {
    switch (step) {
        case 0:
            return <BarForm />
        case 1:
            return <BarInvitationConfirm />
        default:
            throw new Error('Unknown step')
    }
}

export default function BarInvitationStepForm() {

    const { activeStep } = useContext(AppContext);

    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <>
                        {activeStep === labels.length ? (
                            <BarInvitationSuccess />
                        ) : (
                            <>
                                <div style={{ margin: "30px 0 50px" }}>
                                    <Typography variant="h4" align="center">
                                        Street Christmas Bar {DateTime.now().year}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        align="center"
                                        style={{ margin: "10px 0" }}
                                    >
                                        {`${DateTime.fromISO(DateTime.local(DateTime.now().year, 12, 8).toString()).setLocale('da').toLocaleString(DateTime.DATE_FULL)}, at 16:00-21:00`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        align="center"
                                        style={{ margin: "10px 0" }}
                                    >
                                        Rosbjergvej 33, Arslev, 8220 Brabrand
                                    </Typography>
                                </div>
                                <Stepper
                                    activeStep={activeStep}
                                    style={{ margin: "30px 0 15px" }}
                                    alternativeLabel
                                >
                                    {labels.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                                {handleSteps(activeStep)}
                            </>
                        )}
                    </>
                </div>
            </section>
        </div>
    );
}
